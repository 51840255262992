export const EXPLORE_ROUTE_NAMES = ['ExplorePage', 'ExploreSearchOrPreviewer', 'PacksList', 'PacksDetail'];

export const BACK_TO_TOP_SCROLL_OFFSET = 100;

export const DEFAULT_APP_LOCALE = 'en';

export const APP_AVAILABLE_LOCALES = [
	{
		name: 'Spanish',
		locale_code: 'es',
	},
	{
		name: 'German',
		locale_code: 'de',
	},
];

export const APP_AVAILABLE_LOCALE_ROUTE_PARAMS = APP_AVAILABLE_LOCALES.map((locale) => locale.locale_code);
